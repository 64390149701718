import { useEffect, useState, useRef } from "react";

export const useInterval = (cb, intervalDelayMs) => {
  const [isIntervalActive, setIsIntervalActive] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const savedCallback = useRef(cb);

  const startInterval = () => {
    setIsIntervalActive(true);
  };

  const stopInterval = () => {
    setIsIntervalActive(false);
  };

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    if (isIntervalActive) {
      if (intervalDelayMs === null) {
        return;
      }

      setIntervalId(() =>
        setInterval(() => savedCallback.current(), intervalDelayMs)
      );

      return () => clearInterval(intervalId);
    } else if (intervalId) {
      clearInterval(intervalId);
    }
  }, [isIntervalActive, intervalDelayMs]);

  return {
    startInterval,
    stopInterval,
    isIntervalActive,
    intervalId,
  };
};
