import React, { useState, useEffect } from "react";
import SupportedCards from "./SupportedCards";
import { isMobile } from "react-device-detect";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { useAddNewsletterSubscriber } from "../services/hooks/useAddNewsletterSubscriber";
import { joiResolver } from "../services/validations/resolvers/joiResolver";
import { useRecaptcha } from "../services/hooks/useRecaptcha";
import schema from "../services/validations/newsletterSignUpSchema";
import { useSnackbar } from "../services/hooks/useSnackbar";

export default React.memo(function Footer() {
  const {
    addNewsletterSubscriber,
    addedNewsletterSubscriber,
    addingNewsletterSubscriberStatus,
  } = useAddNewsletterSubscriber();

  const { handleSubmit, register, errors, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: joiResolver(schema),
  });

  const { executeRecaptcha, isSafeRequest, recaptchaStatus } = useRecaptcha();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [contactInfo, setContactInfo] = useState();

  const { addSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    executeRecaptcha("add_to_newsletter_request");

    setContactInfo(data);
  };

  useEffect(() => {
    if (recaptchaStatus === "ready" && isSafeRequest && contactInfo) {
      //send newsletter data
      addNewsletterSubscriber(contactInfo);
    }
  }, [recaptchaStatus]);

  useEffect(() => {
    setIsSubmittingForm(
      addingNewsletterSubscriberStatus === "pending" ||
        recaptchaStatus === "pending" ||
        (recaptchaStatus === "ready" &&
          addingNewsletterSubscriberStatus === "idle")
    );
  }, [recaptchaStatus, addingNewsletterSubscriberStatus]);

  useEffect(() => {
    if (addedNewsletterSubscriber === true) {
      addSnackbar("Thank you for subscribing.", {
        type: "success",
      });

      setValue("email", "");
    }
  }, [addedNewsletterSubscriber]);

  return (
    <footer
      className="bg-dark bg-cover @@classList"
      style={{ backgroundImage: `url("/img/patterns/pattern-2.svg")` }}
    >
      <div className="py-12 border-bottom border-gray-700">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-6">
              <h5 className="mb-7 text-center text-white">
                Let's Keep in Touch
              </h5>

              <form className="mb-11" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row align-items-start">
                  <div className="col">
                    <input
                      ref={register}
                      type="email"
                      name="email"
                      className="form-control form-control-gray-700 form-control-lg"
                      placeholder="Enter your email here"
                    />

                    {errors.email && (
                      <span className="input-error">
                        Please enter a valid email address
                      </span>
                    )}
                  </div>
                  <div className="col-auto">
                    <button
                      type="submit"
                      className="btn btn-gray-500 btn-lg"
                      disabled={isSubmittingForm}
                    >
                      {isSubmittingForm ? "Subscribing..." : "Subscribe"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <h4 className="mb-6 text-white">Chic Beauty Box</h4>

              <ul className="list-unstyled list-inline mb-7 mb-md-0">
                <li className="list-inline-item">
                  <a
                    href="https://facebook.com/chicbeautysubscriptionbox"
                    className="text-gray-350"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://instagram.com/chicbeauty.box"
                    className="text-gray-350"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm">
              <h6 className="heading-xxs mb-4 text-white">Support</h6>

              <ul className="list-unstyled mb-7 mb-sm-0">
                <li>
                  <a
                    className="text-gray-300"
                    href="https://help.chicbeautybox.com/portal/en/newticket"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <Link href="/tracking">
                    <a className="text-gray-300">Tracking</a>
                  </Link>
                </li>
                <li>
                  <a
                    className="text-gray-300"
                    href="https://help.chicbeautybox.com"
                    target="_blank"
                  >
                    Help Center
                  </a>
                </li>
                <li>
                  <Link href="/terms/shipping">
                    <a className="text-gray-300">
                      Shipping &amp; Cancellations
                    </a>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-sm">
              <h6 className="heading-xxs mb-4 text-white">Company</h6>

              <ul className="list-unstyled mb-0">
                <li>
                  <Link href="/about">
                    <a className="text-gray-300">Our Story</a>
                  </Link>
                </li>
                <li>
                  <Link href="/community-guidelines">
                    <a className="text-gray-300">Community Guidelines</a>
                  </Link>
                </li>
                <li>
                  <Link href="/accessibility">
                    <a className="text-gray-300">Accessibility</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-sm">
              <h6 className="heading-xxs mb-4 text-white">Legal</h6>

              <ul className="list-unstyled mb-0">
                <li>
                  <Link href="/terms">
                    <a className="text-gray-300">Terms &amp; Conditions</a>
                  </Link>
                </li>
                <li>
                  <Link href="/privacy">
                    <a className="text-gray-300">Privacy &amp; Cookie policy</a>
                  </Link>
                </li>
                <li>
                  <Link href="/terms/ugc-terms">
                    <a className="text-gray-300">UGC Terms</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <span className="d-block font-size-xxs text-muted">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy" target="_blank">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms" target="_blank">
                  Terms of Service
                </a>{" "}
                apply.
              </span>
              <p
                className={`mb-3 mb-md-0 font-size-xxs text-muted ${
                  isMobile && "text-center"
                }`}
              >
                © {new Date().getFullYear()} All rights reserved | Chic Beauty
                Inc. |{" "}
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#dataSettingsModal"
                >
                  Data Settings
                </a>
              </p>
            </div>
            <div className="col-12">
              <SupportedCards />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});
