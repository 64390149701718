import React, { useState, useEffect } from "react";
import { useGlobal } from "../services/hooks/useGlobal";
import { useInterval } from "../services/hooks/useInterval";

export default React.memo(function SupportedCards({ cardType }) {
  const { cdnUrl } = useGlobal();

  const [revolvingCardIconIndex, setRevolvingCardIconIndex] = useState(1);

  const changeRevolvingCardIconIndex = () => {
    setRevolvingCardIconIndex((prevRevolvingCardIconIndex) =>
      prevRevolvingCardIconIndex >= 4 ? 1 : prevRevolvingCardIconIndex + 1
    );
  };

  const { startInterval, stopInterval } = useInterval(
    changeRevolvingCardIconIndex,
    3000
  );

  useEffect(() => {
    if (!cardType) {
      startInterval();

      return () => {
        stopInterval();
      };
    }
  }, [cardType]);

  return (
    <div className={`${cardType ? "d-inline ml-3" : "d-flex flex-row"}`}>
      <span
        className={`px-1 ${cardType && cardType !== "visa" ? "d-none" : ""}`}
      >
        <img
          style={{ height: "1.5rem" }}
          src={`${cdnUrl}/images/site/icons/visa.svg`}
        />
      </span>
      <span
        className={`px-1 ${
          cardType && cardType !== "mastercard" ? "d-none" : ""
        }`}
      >
        <img
          style={{ height: "1.5rem" }}
          src={`${cdnUrl}/images/site/icons/mastercard.svg`}
        />
      </span>
      <span
        className={`px-1 ${cardType && cardType !== "amex" ? "d-none" : ""}`}
      >
        <img
          style={{ height: "1.5rem" }}
          src={`${cdnUrl}/images/site/icons/amex.svg`}
        />
      </span>

      {cardType ? (
        <>
          <span
            className={`px-1 ${
              cardType && cardType !== "discover" ? "d-none" : ""
            }`}
          >
            <img
              style={{ height: "1.5rem" }}
              src={`${cdnUrl}/images/site/icons/discover.svg`}
            />
          </span>

          <span
            className={`px-1 ${
              cardType && cardType !== "unionpay" ? "d-none" : ""
            }`}
          >
            <img
              style={{ height: "1.5rem" }}
              src={`${cdnUrl}/images/site/icons/unionpay.svg`}
            />
          </span>

          <span
            className={`px-1 ${
              cardType && cardType !== "diners" ? "d-none" : ""
            }`}
          >
            <img
              style={{ height: "1.5rem" }}
              src={`${cdnUrl}/images/site/icons/diners.svg`}
            />
          </span>

          <span
            className={`px-1 ${cardType && cardType !== "jcb" ? "d-none" : ""}`}
          >
            <img
              style={{ height: "1.5rem" }}
              src={`${cdnUrl}/images/site/icons/jcb.svg`}
            />
          </span>
        </>
      ) : (
        <>
          {revolvingCardIconIndex === 1 && (
            <span className="px-1">
              <img
                style={{ height: "1.5rem" }}
                src={`${cdnUrl}/images/site/icons/discover.svg`}
              />
            </span>
          )}

          {revolvingCardIconIndex === 2 && (
            <span className="px-1">
              <img
                style={{ height: "1.5rem" }}
                src={`${cdnUrl}/images/site/icons/unionpay.svg`}
              />
            </span>
          )}

          {revolvingCardIconIndex === 3 && (
            <span className="px-1">
              <img
                style={{ height: "1.5rem" }}
                src={`${cdnUrl}/images/site/icons/diners.svg`}
              />
            </span>
          )}

          {revolvingCardIconIndex === 4 && (
            <span className="px-1">
              <img
                style={{ height: "1.5rem" }}
                src={`${cdnUrl}/images/site/icons/jcb.svg`}
              />
            </span>
          )}
        </>
      )}
    </div>
  );
});
